import React, {useEffect, useState} from 'react';
import './Login.css';
import login_back_desktop from './assets/login_back_desktop.jpg';
import logo from "./assets/logo_light.svg";
import {Link} from "react-router-dom";

const PasswordReset = () => {


    const [phone, setPhone] = useState('');
    const [phoneActive, setPhoneActive] = useState(false);
    const [phoneDirty, setPhoneDirty] = useState(false);
    const [phoneError, setPhoneError] = useState(false);
    const [password, setPassword] = useState('');
    const [passwordActive, setPasswordActive] = useState(false);
    const [passwordDirty, setPasswordDirty] = useState(false);
    const [passwordError, setPasswordError] = useState(false);


    const handlePhoneChange = (e) => {

        setPasswordDirty(false);

        if (!isNaN(e.target.value.substring(1))) {
            if (e.target.value.split('')[0] === '+') {
                setPhone(`${e.target.value}`);
            } else {
                setPhone(`+${e.target.value}`);
            }
        }

    }

    const handlePasswordChange = (e) => {
        setPasswordDirty(false);
        setPassword(e.target.value);
    }

    /*useEffect(() => {
        if ((phone === '+' || phone === '+7') && phoneDirty) {
            setPhoneError(true)
        }
        if (password === '' && passwordDirty) {
            setPasswordError(true)
        }
    }, [passwordDirty, phoneDirty])*/


    const [notification, setNotification] = useState(false);

    const setNotificationHandler = () => {

        setNotification(true);

        setTimeout(() => {
            setNotification(false);
        }, 5000)
    }


    const [passwordVisible, setPasswordVisible] = useState(false);


    return (
        <div className={'login-wrapper'}>


            <div
                className={`${notification ? 'active' : ''} notification`}
                onClick={() => setNotification(false)}
            >
                <svg size="24" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"
                     className="styles__IconWrapper-sc-hwukur-0">
                    <path
                        d="M12 4C7.58 4 4 7.58 4 12C4 16.42 7.58 20 12 20C16.42 20 20 16.42 20 12C20 7.58 16.42 4 12 4ZM15.53 14.47C15.82 14.76 15.82 15.24 15.53 15.53C15.38 15.68 15.19 15.75 15 15.75C14.81 15.75 14.62 15.68 14.47 15.53L12 13.06L9.53 15.53C9.38 15.68 9.19 15.75 9 15.75C8.81 15.75 8.62 15.68 8.47 15.53C8.18 15.24 8.18 14.76 8.47 14.47L10.94 12L8.47 9.53C8.18 9.24 8.18 8.76 8.47 8.47C8.76 8.18 9.24 8.18 9.53 8.47L12 10.94L14.47 8.47C14.76 8.18 15.24 8.18 15.53 8.47C15.82 8.76 15.82 9.24 15.53 9.53L13.06 12L15.53 14.47Z"
                        fill="#ffffff"></path>
                </svg>
                Phone number "{phone}" is invalid
            </div>


            <div className={'login-col1'}>

                <div className={'login-col1-top-logo-container'}>
                    <Link to={'/'} style={{transform: 'translateY(2px)'}}>
                        <img
                            src={logo}
                            className={'login-col1-top-logo'}
                        />
                    </Link>
                </div>

                <div className={'progress-line-container'}>
                    <div className={'progress-line'} style={{width: '33%'}}></div>
                </div>


                <div className={'login-col1-top'}>

                    <div className={'login-col1-form'}>

                        <h6>
                            Сброс пароля
                        </h6>


                        <p style={{
                            fontSize: '14px',
                            marginBottom: '24px',
                        }}>
                            Введите свой номер телефона и мы вышлем код для сброса пароля
                        </p>


                        <div className={'input-container first'}>

                            <label className={phoneActive || phone.split('').length > 0 ? 'active' : ''}>
                                Телефон
                            </label>

                            <input
                                type="text"
                                pattern="\+7[0-9]{10}"
                                maxLength="12"
                                name="username"
                                required="true"
                                autoComplete="phone"
                                className="input"
                                style={{borderColor: (phone === '+' || phone === '+7' || phone === '' || phone.split('').length !== 12) && phoneDirty ? 'rgb(229, 100, 100)' : 'rgb(236, 238, 240)'}}
                                placeholder={'Телефон'}
                                value={phone}
                                onChange={(e) => handlePhoneChange(e)}
                                onBlur={() => {
                                    setPhoneDirty(true)
                                    setPhoneActive(false)
                                    if (phone === '+' || phone === '+7' || phone === '') {
                                        setPhone('+7')
                                    }
                                }}
                                onClick={() => {
                                    if (phone === '') {
                                        setPhone('+7')
                                    }
                                    setPhoneActive(true)
                                }}
                            />

                            <p style={{
                                fontSize: '12px',
                                color: 'rgb(229, 100, 100)',
                                fontWeight: '400',
                                lineHeight: '16px',
                                marginLeft: '24px',
                                marginTop: '4px',
                                textAlign: 'start',
                                width: '100%',
                                opacity: (phone === '+' || phone === '+7' || phone.split('').length !== 12) && phoneDirty ? '1' : '0',
                            }}>
                                {
                                    phone.split('').length !== 12 && phone.split('').length > 2 ?
                                        'Допустимый формат: +7XXXXXXXXXX'
                                        :
                                        'Поле обязательно для заполнения'
                                }
                            </p>

                        </div>

                        <div style={{marginTop: '40px'}}>

                            <Link to={'/signup'} className={'btn nothing'}
                                  style={{fontSize: '16px', width: '100%'}}>
                                Регистрация
                            </Link>

                            <div
                                className={'btn blueblue'}
                                style={{
                                    marginTop: '20px',
                                    width: '100%',
                                    // pointerEvents: (password === '') || (phone === '+' || phone === '+7' || phone.split('').length !== 12) ? 'none' : 'all'
                                }}
                                onClick={() => {
                                    setPasswordDirty(true);
                                    setPhoneDirty(true);
                                    if ((phone === '+' || phone === '+7' || phone.split('').length !== 12)) {
                                    } else {
                                        setNotificationHandler()
                                    }
                                }}
                            >
                                Далее
                            </div>

                        </div>


                    </div>


                </div>

            </div>

            <div className={'login-col2'}>

                {/*<img className={'main-container-logo-img'} src={login_back_desktop}/>*/}

            </div>

        </div>
    );
};

export default PasswordReset;