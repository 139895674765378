import React, {useEffect, useState} from 'react';
import './Login.css';
import login_back_desktop from './assets/login_back_desktop.jpg';
import logo from "./assets/logo_light.svg";
import {Link} from "react-router-dom";

const Login = () => {


    const [phone, setPhone] = useState('');
    const [phoneActive, setPhoneActive] = useState(false);
    const [phoneDirty, setPhoneDirty] = useState(false);
    const [phoneError, setPhoneError] = useState(false);
    const [password, setPassword] = useState('');
    const [passwordActive, setPasswordActive] = useState(false);
    const [passwordDirty, setPasswordDirty] = useState(false);
    const [passwordError, setPasswordError] = useState(false);


    const handlePhoneChange = (e) => {

        setPasswordDirty(false);

        if (!isNaN(e.target.value.substring(1))) {
            if (e.target.value.split('')[0] === '+') {
                setPhone(`${e.target.value}`);
            } else {
                setPhone(`+${e.target.value}`);
            }
        }

    }

    const handlePasswordChange = (e) => {
        setPasswordDirty(false);
        setPassword(e.target.value);
    }

    /*useEffect(() => {
        if ((phone === '+' || phone === '+7') && phoneDirty) {
            setPhoneError(true)
        }
        if (password === '' && passwordDirty) {
            setPasswordError(true)
        }
    }, [passwordDirty, phoneDirty])*/


    const [notification, setNotification] = useState(false);

    const setNotificationHandler = () => {

        setNotification(true);

        setTimeout(() => {
            setNotification(false);
        }, 5000)
    }


    const [passwordVisible, setPasswordVisible] = useState(false);


    return (
        <div className={'login-wrapper'}>


            <div
                className={`${notification ? 'active' : ''} notification`}
                onClick={() => setNotification(false)}
            >
                <svg size="24" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"
                     className="styles__IconWrapper-sc-hwukur-0">
                    <path
                        d="M12 4C7.58 4 4 7.58 4 12C4 16.42 7.58 20 12 20C16.42 20 20 16.42 20 12C20 7.58 16.42 4 12 4ZM15.53 14.47C15.82 14.76 15.82 15.24 15.53 15.53C15.38 15.68 15.19 15.75 15 15.75C14.81 15.75 14.62 15.68 14.47 15.53L12 13.06L9.53 15.53C9.38 15.68 9.19 15.75 9 15.75C8.81 15.75 8.62 15.68 8.47 15.53C8.18 15.24 8.18 14.76 8.47 14.47L10.94 12L8.47 9.53C8.18 9.24 8.18 8.76 8.47 8.47C8.76 8.18 9.24 8.18 9.53 8.47L12 10.94L14.47 8.47C14.76 8.18 15.24 8.18 15.53 8.47C15.82 8.76 15.82 9.24 15.53 9.53L13.06 12L15.53 14.47Z"
                        fill="#ffffff"></path>
                </svg>
                Недействительные аутентификационные данные
            </div>


            <div className={'login-col1'}>

                <div className={'login-col1-top-logo-container'}>
                    <Link to={'/'} style={{transform: 'translateY(2px)'}}>
                        <img
                            src={logo}
                            className={'login-col1-top-logo'}
                        />
                    </Link>
                </div>

                <div className={'progress-line-container'}>
                    <div className={'progress-line'} style={{width: '0%'}}></div>
                </div>


                <div className={'login-col1-top'}>

                    <div className={'login-col1-form'}>

                        <h6>
                            Вход в аккаунт
                        </h6>


                        <div className={'input-container first'}>

                            <label className={phoneActive || phone.split('').length > 0 ? 'active' : ''}>
                                Телефон
                            </label>

                            <input
                                type="text"
                                pattern="\+7[0-9]{10}"
                                maxLength="12"
                                name="username"
                                required="true"
                                autoComplete="phone"
                                className="input"
                                style={{borderColor: (phone === '+' || phone === '+7' || phone === '' || phone.split('').length !== 12) && phoneDirty ? 'rgb(229, 100, 100)' : 'rgb(236, 238, 240)'}}
                                placeholder={'Телефон'}
                                value={phone}
                                onChange={(e) => handlePhoneChange(e)}
                                onBlur={() => {
                                    setPhoneDirty(true)
                                    setPhoneActive(false)
                                    if (phone === '+' || phone === '+7' || phone === '') {
                                        setPhone('+7')
                                    }
                                }}
                                onClick={() => {
                                    if (phone === '') {
                                        setPhone('+7')
                                    }
                                    setPhoneActive(true)
                                }}
                            />

                            <p style={{
                                fontSize: '12px',
                                color: 'rgb(229, 100, 100)',
                                fontWeight: '400',
                                lineHeight: '16px',
                                marginLeft: '24px',
                                marginTop: '4px',
                                textAlign: 'start',
                                width: '100%',
                                opacity: (phone === '+' || phone === '+7' || phone.split('').length !== 12) && phoneDirty ? '1' : '0',
                            }}>
                                {
                                    phone.split('').length !== 12 && phone.split('').length > 2 ?
                                        'Допустимый формат: +7XXXXXXXXXX'
                                        :
                                        'Поле обязательно для заполнения'
                                }
                            </p>

                        </div>


                        <div className={'input-container'}>

                        {
                                passwordVisible ?
                                    <svg
                                        onClick={() => setPasswordVisible(!passwordVisible)}
                                        color="#666666"
                                        width="1em"
                                        height="1em"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                        style={{
                                            zIndex: 10000,
                                            width: '24px',
                                            height: '24px',
                                            position: 'absolute',
                                            right: '12px',
                                            top: '11px'
                                        }}
                                    >
                                        <path
                                            d="M15.0398 11.0435L14.0808 12.0025C14.0299 12.0534 13.9992 12.1215 13.9952 12.1931C13.9402 13.2224 13.1065 14.0458 12.0727 14.0836C12.0249 14.0854 11.979 14.1039 11.9452 14.1377L10.9479 15.135C10.9082 15.1746 10.9249 15.2417 10.9781 15.2589C11.3533 15.3796 11.7573 15.4354 12.1772 15.4134C13.8691 15.3242 15.238 13.9553 15.3267 12.2638C15.3484 11.848 15.2938 11.448 15.1758 11.0764C15.1574 11.0179 15.083 10.9998 15.0398 11.0435Z"
                                            fill="currentColor"></path>
                                        <path
                                            d="M21.8587 11.674C21.8587 11.674 20.3979 9.80824 18.1249 8.23976C18.0294 8.17399 17.9006 8.18526 17.8186 8.26724L17.0821 9.00373C17.038 9.04787 17.0456 9.12175 17.0983 9.15598C17.1132 9.16544 17.1276 9.17535 17.1425 9.18526C18.7191 10.2308 19.9046 11.46 20.4601 12.0857C19.9046 12.7119 18.7195 13.9411 17.1425 14.9862C15.6997 15.9421 13.9253 16.7524 12.0006 16.7524C11.2645 16.7524 10.5505 16.6335 9.86856 16.4339C9.76495 16.4038 9.65369 16.4326 9.57712 16.5092L8.79828 17.288C8.73252 17.3538 8.75729 17.4655 8.84513 17.4975C9.83703 17.8601 10.8983 18.0858 12.0006 18.0858C14.1974 18.0858 16.2326 17.1894 17.8799 16.097C20.2925 14.4974 21.8583 12.497 21.8583 12.497C22.0474 12.2555 22.0474 11.9155 21.8583 11.6745L21.8587 11.674Z"
                                            fill="currentColor"></path>
                                        <path
                                            d="M13.5064 10.7705L14.4505 9.82638L18.2339 6.04347C18.4726 5.80473 18.4726 5.41779 18.2339 5.17905C17.9951 4.94032 17.6082 4.94032 17.3694 5.17905L15.6712 6.87726C14.5347 6.39753 13.2956 6.08537 12 6.08537C9.80319 6.08537 7.76805 6.98222 6.12074 8.07411C3.70812 9.67277 2.14189 11.6737 2.14189 11.6737C1.9527 11.9151 1.9527 12.2552 2.14189 12.4962C2.14189 12.4962 3.70767 14.4967 6.12074 16.0962C6.18696 16.1399 6.25453 16.1827 6.32164 16.226L4.93019 17.6174C4.69145 17.8561 4.69145 18.2431 4.93019 18.4818C5.16893 18.7206 5.55587 18.7206 5.79461 18.4818L13.5064 10.7705ZM6.85813 14.9854C5.28155 13.9399 4.09596 12.7106 3.541 12.0849C4.09641 11.4588 5.28155 10.2295 6.85813 9.18448C8.30093 8.22862 10.0753 7.41871 12 7.41871C12.9226 7.41871 13.8104 7.60564 14.6438 7.90429L13.4591 9.08898C13.0181 8.87367 12.5235 8.7516 12 8.7516C10.1599 8.7516 8.6667 10.2448 8.6667 12.0849C8.6667 12.6084 8.78832 13.103 9.00409 13.544L7.28876 15.2593C7.14237 15.1696 6.99867 15.0782 6.85813 14.9854ZM10.0446 12.5034C10.0158 12.3683 10 12.2286 10 12.0849C10 10.9809 10.896 10.0849 12 10.0849C12.1437 10.0849 12.2834 10.1007 12.4185 10.1295L10.0446 12.5034Z"
                                            fill="currentColor"></path>
                                    </svg>
                                    :
                                    <svg
                                        onClick={() => setPasswordVisible(!passwordVisible)}
                                        color="#666666"
                                        width="1em"
                                        height="1em"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                        style={{
                                            zIndex: 10000,
                                            width: '24px',
                                            height: '24px',
                                            position: 'absolute',
                                            right: '12px',
                                            top: '11px'
                                        }}
                                    >
                                        <path fill-rule="evenodd" clip-rule="evenodd"
                                              d="M12 6C9.80334 6 7.768 6.89667 6.12067 7.98867C3.70799 9.58801 2.142 11.5887 2.142 11.5887C1.95267 11.83 1.95267 12.17 2.142 12.4113C2.142 12.4113 3.70799 14.412 6.12067 16.0114C7.768 17.1034 9.80334 18 12 18C14.1967 18 16.232 17.1034 17.8794 16.0114C20.292 14.412 21.858 12.4113 21.858 12.4113C22.0474 12.17 22.0474 11.83 21.858 11.5887C21.858 11.5887 20.292 9.58801 17.8794 7.98867C16.232 6.89667 14.1967 6 12 6ZM12 7.33334C13.9247 7.33334 15.6994 8.14333 17.142 9.09933C18.7187 10.1447 19.904 11.374 20.4594 12C19.904 12.626 18.7187 13.8554 17.142 14.9007C15.6994 15.8567 13.9247 16.6667 12 16.6667C10.0753 16.6667 8.30066 15.8567 6.85799 14.9007C5.28132 13.8554 4.096 12.626 3.54066 12C4.096 11.374 5.28132 10.1447 6.85799 9.09933C8.30066 8.14333 10.0753 7.33334 12 7.33334ZM12 8.66667C10.16 8.66667 8.66667 10.16 8.66667 12C8.66667 13.84 10.16 15.3334 12 15.3334C13.84 15.3334 15.3334 13.84 15.3334 12C15.3334 10.16 13.84 8.66667 12 8.66667ZM12 10C13.104 10 14 10.896 14 12C14 13.104 13.104 14 12 14C10.896 14 10 13.104 10 12C10 10.896 10.896 10 12 10Z"
                                              fill="currentColor"></path>
                                    </svg>
                            }

                            <label className={passwordActive || password.split('').length > 0 ? 'active' : ''}>
                                Пароль
                            </label>

                            <input
                                type={passwordVisible ? 'text' : 'password'}
                                name="password"
                                required="true"
                                autoComplete="password"
                                className="input"
                                style={{
                                    borderColor: password === '' && passwordDirty ? 'rgb(229, 100, 100)' : 'rgb(236, 238, 240)'
                                }}
                                value={password}
                                onChange={(e) => handlePasswordChange(e)}
                                onBlur={() => {
                                    setPasswordDirty(true);
                                    setPasswordActive(false);
                                }}
                                onClick={() => setPasswordActive(true)}
                            />


                            <p style={{
                                fontSize: '12px',
                                color: 'rgb(229, 100, 100)',
                                fontWeight: '400',
                                lineHeight: '16px',
                                marginLeft: '24px',
                                marginTop: '4px',
                                textAlign: 'start',
                                width: '100%',
                                opacity: password === '' && passwordDirty ? '1' : '0',
                            }}>
                                Поле обязательно для заполнения
                            </p>


                        </div>


                        <Link to={'/password-reset'}>
                            <p style={{
                                padding: '14px 40px',
                                marginTop: 'calc(26px)',
                                fontSize: '14px',
                                color: 'rgb(104, 114, 133)'
                            }}>
                                Забыли пароль?
                            </p>
                        </Link>


                        <div style={{marginTop: '40px'}}>

                            <Link to={'/signup'} className={'btn nothing'}
                                  style={{fontSize: '16px', width: '100%'}}>
                                Регистрация
                            </Link>

                            <div
                                className={'btn blueblue'}
                                style={{
                                    marginTop: '20px',
                                    width: '100%',
                                    // pointerEvents: (password === '') || (phone === '+' || phone === '+7' || phone.split('').length !== 12) ? 'none' : 'all'
                                }}
                                onClick={() => {
                                    setPasswordDirty(true);
                                    setPhoneDirty(true);
                                    if ((password === '') || (phone === '+' || phone === '+7' || phone.split('').length !== 12)) {
                                    } else {
                                        setNotificationHandler()
                                    }
                                }}
                            >
                                Войти
                            </div>

                        </div>


                    </div>


                </div>

            </div>

            <div className={'login-col2'}>

                {/*<img className={'main-container-logo-img'} src={login_back_desktop}/>*/}

            </div>

        </div>
    );
};

export default Login;