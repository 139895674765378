import React from 'react';
import './Main.css';
import back_desktop from './assets/main_back_desktop.jpg';
import logo from './assets/logo.svg';
import logo_white from './assets/logo_white.svg';
import {Link} from "react-router-dom";

function Main() {
    return (
        <div className={'main-wrapper'}>

            <img
                src={logo}
                className={'main-container-logo-img'}
            />
            <img
                src={logo_white}
                className={'main-container-logo-img-mobile'}
            />

            <div className={'main-container'}>

                <div className={'main-content'}>

                    <h3>
                        Стань одним из нас!
                    </h3>

                    <p>
                        Зарегистрируйся, чтобы зарабатывать вместе с нами. Регистрация возможна только при наличии партнёра.
                    </p>


                    <div>
                        <Link to={'/login'} className={'btn outline'}>
                            Войти
                        </Link>
                        <Link to={'/signup'} className={'btn blue'} style={{marginTop: '20px'}}>
                            Зарегистрироваться
                        </Link>
                    </div>


                </div>

            </div>

        </div>
    );
};

export default Main;