import './App.css';
import Main from "./Main";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Login from "./Login";
import SignUp from "./SignUp";
import PasswordReset from "./PasswordReset";

function App() {
    return (
        <>

            <Routes>
                <Route path="/" element={<Main />} />
                <Route exact path="/login" element={<Login />} />
                <Route exact path="/signup" element={<SignUp />} />
                <Route exact path="/password-reset" element={<PasswordReset />} />
            </Routes>

        </>
    );
}

export default App;
